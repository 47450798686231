import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import SearchInput from '../../SearchInput'
import BigTooltip from '../../../Tooltip/BigTooltip'
import { AddCircleOutline } from '@mui/icons-material'
import ScrollContainer from '../../../Scroll/ScrollContainer'
import Item from '../SelectInputItem/Item'
import { useContext, useEffect, useMemo, useState } from 'react'
import { EntitySelectInputContext } from '../EntitySelectInput'
import { inline_title_no_wrap } from '../../../../Utils/defaultStyles'
import SearchField from '../../SearchField'
import newTheme from '../../../../newTheme'
import { useDebounceFunctionCallback } from '../../../../Hooks/DebounceHook'

function SelectInputMenu({ openMenu, onClose, anchorEl, data }) {

	const { t, valueAsArray, title, onCreate, search, setSearch, onSelect, field, loading, nextPage } = useContext(EntitySelectInputContext)
	const [animationEnd, setAnimationEnd] = useState(false)
	const [enableCreate, setEnableCreate] = useState(false)
	const [bestMatchingItem, setBestMatchingItem] = useState(null)
	const debounceFunction = useDebounceFunctionCallback(500)

	useEffect(() => { if (openMenu) setAnimationEnd(false) }, [openMenu])

	useEffect(() => {
		if (data) {
			const searchMatch = data.find(item => item[field]?.toLowerCase() === search?.toLowerCase())
			const bestMatch = searchMatch || data.find(item => item[field]?.toLowerCase().includes(search?.toLowerCase()))
			if (!!search) {
				setEnableCreate(!searchMatch)
				setBestMatchingItem(bestMatch)
			} else {
				setEnableCreate(false)
				setBestMatchingItem(null)
			}
		}
	}, [data])

	function onEnterKey(searchText) {
		if (!!bestMatchingItem) return onSelect(bestMatchingItem)
		if (enableCreate) return handleCreate(searchText)
		const searchedItem = data.find(item => item[field]?.toLowerCase() === searchText?.toLowerCase())
		return onSelect(searchedItem)
	}

	function handleCreate(name) {
		const body = { [field]: name }
		onCreate(body)
	}

	function loadNextPage() {
		function callback() {
			if (loading) return
			nextPage()
		}
		debounceFunction(callback)
	}

	useEffect(() => {
		if (!animationEnd) { setTimeout(() => { setAnimationEnd(true) }, 250) }
	}, [animationEnd])

	return (
		<Menu
			open={openMenu}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			slotProps={{
				paper: {
					sx: { overflow: 'visible' }
				}
			}}
		>
			<Box sx={{ padding: 2 }}>
				<Box sx={inline_title_no_wrap}>
					<SearchField icon label={t('selectCreateInput.searchLabel', { type: title })} onChange={(e) => setSearch(e)} value={search} onEnterKey={onEnterKey} />
					<BigTooltip title={t('selectCreateInput.createLabel', { type: title })}>
						{!!enableCreate &&
							<IconButton onClick={onCreate} disabled={!enableCreate} >
								<AddCircleOutline />
							</IconButton>
						}
					</BigTooltip>
				</Box>
				<Box>
					{data.length === 0 && search === "" && <Typography sx={{ padding: 2 }}>{t('selectCreateInput.noElements')} </Typography>}

					{animationEnd &&
						<ScrollContainer open={openMenu} maxHeight={180} spacing={0} height={50} onScrollEnd={loadNextPage} >
							{data.map(item => {
								const isSelected = !!valueAsArray?.length && valueAsArray.some(selectedItem => selectedItem.id === item.id)
								const isMatch = bestMatchingItem?.id === item.id
								return <Item key={item.id} item={item} isSelected={isSelected} isMatch={isMatch} />
							})}
						</ScrollContainer>
					}
					{enableCreate && search !== "" && !loading &&
						<MenuItem sx={{
							padding: 2,
							background: newTheme.palette.success.main + '22',
							border: `1px solid ${newTheme.palette.success.main}`,
							borderRadius: 1
						}}
							onClick={() => handleCreate(search)}>
							<Typography>Crear <strong>{search}</strong></Typography>
						</MenuItem>
					}
				</Box>
			</Box>
		</Menu>
	)
}

export default SelectInputMenu