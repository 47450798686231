export function addRemoveInARrayOutOfPlace(arr, element) {
	const newArr = arr ? [...arr] : []
	const idsArr = newArr.map(item => item?.id)
	if (idsArr.includes(element.id)) {
		newArr.splice(idsArr.indexOf(element.id), 1)
		return newArr
	}
	newArr.push(element)
	return newArr
}

export function moveDataToFirstPage(queryClient, newItem, page = 0, id = null) {
	if (Array.isArray(newItem) ? newItem.length === 0 : !newItem.id) return

	const queryKey = [id, { page: 0, search: "" }]
	const oldData = queryClient.getQueryData(queryKey)

	const normalizedNewItem = Array.isArray(newItem) ? newItem : [newItem]
	const lastNewItem = normalizedNewItem[normalizedNewItem.length - 1]

	const isItemInFirstPage = oldData?.data?.info?.some(item => item.id === lastNewItem.id)
	if (isItemInFirstPage) return

	queryClient.setQueryData(queryKey, (oldData) => ({
		...oldData,
		data: {
			...oldData?.data,
			info: [lastNewItem, ...(oldData?.data?.info || [])],
		},
	}))

	const previousQueryKey = [id, { page, search: "" }];
	queryClient.setQueryData(previousQueryKey, (oldData) => ({
		...oldData,
		data: {
			...oldData?.data,
			info: removeItemFromPage(oldData?.data?.info, normalizedNewItem),
		},
	}))
}

function removeItemFromPage(info, itemsToRemove) {
	if (!info) return []
	const idsToRemove = new Set(itemsToRemove.map(item => item.id))
	return info.filter(item => !idsToRemove.has(item.id))
}
