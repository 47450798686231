import { Box, Chip, IconButton, Typography } from "@mui/material"
import { inline } from "../../../Utils/defaultStyles"
import { Close, CollectionsBookmarkOutlined, KeyboardArrowDown } from "@material-ui/icons"
import { useState, useRef, useEffect, useMemo, createContext } from "react"
import useToggle from "../../../Hooks/ToogleHook"
import { useTranslation } from "react-i18next"
import SelectInputMenu from "./SelectInputMenu/SelectInputMenu"
import { addRemoveInARrayOutOfPlace, moveDataToFirstPage } from "./utils"
import { useQuery, useQueryClient } from '@tanstack/react-query'

const css = {
	container: {
		cursor: "pointer",
		border: "1px solid lightgray",
		borderRadius: 1,
		'&:hover': {
			border: "1px solid black"
		},
		minHeight: 37,
		minWidth: 200,
		padding: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	hiddenChips: {
		display: 'flex',
		gap: 2,
		maxWidth: '100%',
		overflow: 'hidden'
	},
	label: {
		position: "absolute",
		top: -6,
		left: 6,
		background: "white",
		px: 0.5,
		fontSize: "0.75rem",
		padding: "0 0.5rem",
	},
	disabled: {
		background: "whitesmoke",
		pointerEvents: "none",
		cursor: "not-allowed",
	}
}

export const EntitySelectInputContext = createContext({})

function EntitySelectInput({
	value = null,
	valueName = null,
	fetchEndpoint,
	body = {},
	onChange,
	createCallback,
	createEndpoint,
	updateCallback,
	updateEndpoint,
	deleteCallback,
	deleteEndpoint,
	field = "name",
	name,
	title,
	id,
	isMultiple = false,
	limit = 8,
	hidden = false,
	emptyLabel = null,
	label = "",
	disabled = false
}) {
	const [selected, setSelected] = useState(null)
	const [openMenu, toggleMenu] = useToggle(false)
	const [accumulatedData, setAccumulatedData] = useState([])
	const [maxPages, setMaxPages] = useState(0)
	const [search, setSearch] = useState("")
	const [page, setPage] = useState(0)
	const menu = useRef(null)
	const { t } = useTranslation('input')

	const queryClient = useQueryClient()

	const { data, isLoading, isError, error, isFetching, refetch } = useQuery({
		queryKey: [id, { page, search }],
		queryFn: () => fetchEndpoint({ limit, offset: page * limit, ...body, search, id: search ? null : (selected?.id || value) }),
		staleTime: Infinity,
		select: (response) => {
			return {
				info: response.data.info,
				pages: response.data.pages,
			}
		},
	})

	useEffect(() => {
		if (!data) return
		setMaxPages(data?.pages)
		if (!search && !selected) {
			Array.isArray(value) ?
				setSelected(data?.info.filter(item => value.includes(item?.id)))
				: setSelected(data?.info.find(item => item?.id === value
				))
		}
		if (page === 0) setAccumulatedData(data?.info)
		else {
			setAccumulatedData((prevData) => {
				const newData = data?.info?.filter(
					(newItem) => !prevData.some((existingItem) => existingItem.id === newItem.id)
				)
				return [...prevData, ...newData]
			})
		}
	}, [data])

	function onSelect(item) {
		let newSelected = !!selected?.length ? selected : null
		if (isMultiple) {
			newSelected = addRemoveInARrayOutOfPlace(newSelected, item)
		} else {
			newSelected = item?.id === selected?.id ? null : item
		}
		const ids = Array.isArray(newSelected)
			? newSelected.map(i => i?.id).filter(Boolean)
			: newSelected?.id ?? null
		const responseEvent = { target: { name, value: ids } }
		setSelected(newSelected)
		moveDataToFirstPage(queryClient, newSelected, page, id)
		onChange(responseEvent)
		toggleMenu()
	}

	async function onCreate(body) {
		if (!createEndpoint) return
		const response = await createEndpoint(body)
		const newItem = response.data.info
		setAccumulatedData((prevData) => [newItem, ...prevData])
		if (isMultiple) onSelect((prevSelected) => [...prevSelected, newItem])
		else onSelect(newItem)
	}

	function nextPage() {
		setPage((prevPage) => {
			if (prevPage + 1 < maxPages) { return prevPage + 1 }
			return prevPage
		})
	}

	function onToggleMenu() {
		toggleMenu()
		setSearch("")
	}

	function onSearchChange(event) {
		setPage(0)
		setSearch(event)
	}

	const valueToArray = useMemo(() => {
		if (!selected) return []
		if (isMultiple && Array.isArray(selected)) return selected
		return [selected]
	}, [selected])

	return (
		<EntitySelectInputContext.Provider value={{
			body,
			onSelect,
			selected,
			data: accumulatedData,
			setData: setAccumulatedData,
			updateCallback,
			updateEndpoint,
			onCreate,
			maxHeight: '100%',
			field,
			deleteEndpoint,
			deleteCallback,
			name,
			t,
			keyName: field,
			title,
			setSearch: onSearchChange,
			valueAsArray: valueToArray,
			loading: isLoading || isFetching,
			search,
			nextPage,
			refetch
		}}>
			{hidden ?
				<Box onClick={disabled ? undefined : onToggleMenu} ref={menu} sx={{ ...css.hiddenChips, ...(disabled && css.disabled) }}>
					{valueToArray?.map(value => <Chip clickable key={value.id} label={value?.[field]} />)}
					{valueToArray?.length === 0 && <Chip clickable color="primary" variant="outlined" label={valueName || emptyLabel || t('noValue')} />}
				</Box>
				:

				<Box sx={{ position: "relative", width: "100%", m: "12px 0" }}>
					<Typography sx={css.label}>{label}</Typography>
					<Box sx={{ ...css.container, ...(disabled && css.disabled) }} onClick={disabled ? undefined : onToggleMenu} ref={menu} disabled={disabled}>
						<Box sx={{ ...inline, flexGrow: 1, overflow: 'hidden' }}>
							{!!valueToArray?.length &&
								valueToArray.map(value =>
									<Chip
										key={value.id}
										label={value?.[field]}
										deleteIcon={<Close />}
										onDelete={() => onSelect(value)}
									/>
								)
							}
						</Box>
						<Box sx={{ minWidth: 24, color: "grey" }}>
							<KeyboardArrowDown sx={{ marginLeft: 'auto', color: 'white' }} />
						</Box>
					</Box>
				</Box>

			}
			<SelectInputMenu openMenu={openMenu} onClose={onToggleMenu} anchorEl={menu.current} data={accumulatedData} />
		</EntitySelectInputContext.Provider >
	)
}

export default EntitySelectInput